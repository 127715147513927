import React, { useState, useEffect, useContext } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { languages } from "./languages";
import { booleanContext } from "../../App";

function RequestTypes(props) {
  const selectedLang = useContext(booleanContext);
  const getText = (attr) => {
    if (selectedLang) {
      return languages[selectedLang].requestTypes[attr];
    }
    return languages.en.requestTypes[attr];
  };
  const {
    requestTypes,
    onUpdateRequestType,
    onAccessRequestType,
    relationshipType,
  } = props;
  const [checkedRequestTypes, setCheckedRequestTypes] = useState([]);
  const [requestType, setRequestType] = useState([]);

  const handleChange = (e) => {
    if (e.target?.value) {
      e.stopPropagation();
    }
    const inputValue = e.target?.value || e;
    const indexVal = requestType.indexOf(e.target?.id);
    if (indexVal === -1) {
      setRequestType([...requestType, e.target?.id]);
    } else {
      setRequestType(requestType.filter((ele) => ele !== e.target?.id));
    }
 
    let currentRequestTypes = [...checkedRequestTypes];
    if (currentRequestTypes.indexOf(inputValue) === -1) {
      currentRequestTypes = [...currentRequestTypes, inputValue];
    } else {
      currentRequestTypes = currentRequestTypes.filter(
        (request) => request !== inputValue
      );
    }

    console.log("currentRequestTypes", currentRequestTypes);
    setCheckedRequestTypes(currentRequestTypes);
    props.onRequestTypesChange(currentRequestTypes);
  };

  useEffect(() => {
    if (requestType) {
      const checkElements = ["access", "correction"];
      if (checkElements.every((color) => requestType.includes(color))) {
        onUpdateRequestType(true);
        onAccessRequestType(true);
      } else if (requestType.includes("correction")) {
        onUpdateRequestType(true);
        onAccessRequestType(false);
      } else if (requestType.includes("access")) {
        onAccessRequestType(true);
        onUpdateRequestType(false);
      } else {
        onUpdateRequestType(false);
        onAccessRequestType(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestType]);

  useEffect(() => {
    if (relationshipType) {
      const configRequestTypes = requestTypes.map((req) => req.value);
      let currentRequestTypes = [...checkedRequestTypes];
      currentRequestTypes = currentRequestTypes.filter((req) =>
        configRequestTypes.includes(req)
      );

      setCheckedRequestTypes(currentRequestTypes);
      props.onRequestTypesChange(currentRequestTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipType, requestTypes]);

  return (
    <>
      <p className="my-3">
        <strong className="label-text">
          {getText("contactReason")}: <span className="text-danger">*</span>
        </strong>
      </p>
      <Row xs={1} className="g-3 mb-4">
        {requestTypes &&
          requestTypes.map((requestType, index) => {
            return (
              <Col key={`${requestType.value}`}>
                <Card className="card-requestType" key={`${requestType.value}`}>
                  <Card.Body>
                    <Form.Check>
                      <Form.Check.Input
                        type="checkbox"
                        value={`${requestType.value}`}
                        id={`${requestType.value}`}
                        onClick={handleChange}
                      />
                      <Form.Check.Label className="h6">
                        {requestType.description}
                      </Form.Check.Label>
                    </Form.Check>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
      </Row>
    </>
  );
}

export default RequestTypes;
