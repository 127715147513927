export const languages = {
  en: {
    intakeForm: {
      title: "Privacy Request",
      accessDescription:
        "Inform me about the processing of my personal information",
      deleteDescription: "Delete my personal information",
      correctionDescription: "Update/Correct my personal information",
      optOutDescription: "Opt-out of marketing communications",
      portabilityDescription: "Copy of my personal information",
      dataProcessingDescription: "Withdraw of consent",
      customer: "Customer/Farmer/Retailer/Distributor",
      employee: "Employee/Applicant/Former employee/Contractor",
      vendor: "Vendor",
    },
    requestTypes: {
      contactReason: "Contact Reason",
    },
    requestForm: {
      email: "Email",
      firstName: "First Name",
      lastName: "Last Name",
      phone: "Phone Number",
      country: "Country",
      countryPlaceholder: "Select Country *",
      company: "Company",
      address: "Address",
      province: "Province/Territory",
      cityTown: "City/Town",
      provincePlaceholder: "Select Province/Territory",
      robot: "I'm not a robot",
      submitRequest: "Submit Request",
      errorText: "A valid number is required",
      relationshipToCorteva: "Relationship to Corteva",
      customer: "Customer/Farmer/Retailer/Distributor",
      employee: "Employee/Applicant/Former employee/Contractor",
      vendor: "Vendor",
      other: "Other",
      updateRequestDetails: "Please specify what needs to be updated/corrected",
      updateRequestDetailsPlaceholder:
        "Please don't provide any sensitive personal information through this form",
      accessRequestDetails: "Please provide details about your request",
      pleaseSpecify: "Please Specify *",
    },
    acknowledgement: {
      title: "Confirmation",
      ackText1: "Your Privacy Request ID is",
      ackText2: "Please save a copy for your records.",
      ackText3:
        "A verification link will be sent to the email address you provided for all request types except for an",
      unsubscribe: "unsubscribe",
      ackText4: "request",
      ackText5:
        "Please check your inbox to verify your request. If you did not receive an email from",
      ackText6:
        "please check your spam folder or resubmit your request. Thank you.",
    },
  },
  fr_ca: {
    intakeForm: {
      title: "Demande relative à la confidentialité",
      accessDescription:
        "M'informer du traitement de mes renseignements personnels",
      deleteDescription: "Supprimer mes renseignements personnels",
      correctionDescription:
        "Mettre à jour/corriger mes renseignements personnels",
      optOutDescription: "Désactiver les communications marketing",
      portabilityDescription: "Copie de mes renseignements personnels",
      dataProcessingDescription: "Retrait du consentement",
      customer: "Client/Agriculteur/Détaillant/Distributeur",
      employee: "Employé/Candidat/Ancien employé/Prestataire",
      vendor: "Fournisseur",
    },
    requestTypes: {
      contactReason: "Raison du contact",
    },
    requestForm: {
      email: "Email",
      firstName: "Prénom",
      lastName: "Nom de famille",
      phone: "Numéro de téléphone",
      country: "Pays",
      countryPlaceholder: "Sélectionner pays *",
      company: "Entreprise",
      address: "Adresse",
      province: "Province/Territoire",
      cityTown: "Ville",
      provincePlaceholder: "Sélectionnez la province/le territoire",
      submitRequest: "Soumettre une demande",
      errorText: "Un numéro valide est requis",
      relationshipToCorteva: "Relation avec Corteva",
      customer: "Client/Agriculteur/Détaillant/Distributeur",
      employee: "Employé/Candidat/Ancien employé/Prestataire",
      vendor: "Fournisseur",
      pleaseSpecify: "Veuillez préciser *",
      updateRequestDetails:
        "Veuillez préciser ce qui doit être mis à jour/corrigé",
      updateRequestDetailsPlaceholder:
        "Veuillez ne pas fournir d'informations personnelles sensibles via ce formulaire",
      accessRequestDetails: "Veuillez fournir des détails sur votre demande",
    },
    acknowledgement: {
      title: "Confirmation",
      ackText1: "Votre ID de demande de confidentialité est",
      ackText2: "Conservez-en une copie dans vos archives.",
      ackText3:
        "Un lien de vérification sera envoyé à l’adresse e-mail indiquée pour les demandes de tous types à l’exception des demandes de",
      unsubscribe: "désabonnement",
      ackText4: "",
      ackText5:
        "Consultez votre boîte de réception pour vérifier votre demande. Si vous n’avez reçu aucun e-mail de",
      ackText6:
        "vérifiez votre dossier de spam ou soumettez une nouvelle demande. Merci.",
    },
  },
};
