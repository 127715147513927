import React, { useContext } from "react";
import { Container, Card } from "react-bootstrap";
import { booleanContext } from "../App";
import { languages } from "../shared/components/languages";

function Acknowledgement(props) {
  const { id } = props;
  const selectedLang = useContext(booleanContext);
  const getText = (attr) => {
    if (selectedLang) {
      return languages[selectedLang].acknowledgement[attr];
    }
    return languages.en.acknowledgement[attr];
  };

  return (
    <div>
      <Container className="my-5">
        <Card>
          <Card.Body className="ml-2 text-justify">
            <Card.Title className="mb-5 font-weight-bold">
              <u>
                <h3 className="text-center">{getText("title")}</h3>
              </u>
            </Card.Title>
            <div className="mb-5">
              <Card.Text>
                {getText("ackText1")} <strong>{id}</strong>.{" "}
                {getText("ackText2")}
              </Card.Text>

              <Card.Text>
                {getText("ackText5")}{" "}
                <a
                  href="mailto: no-reply@privacyrequest.corteva.com"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="no-reply@privacyrequest.corteva.com"
                >
                  no-reply@privacyrequest.corteva.com
                </a>
                , {getText("ackText6")}
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Acknowledgement;
