import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import IntakeForm from "./components/IntakeForm";
import "./App.scss";

export const booleanContext = React.createContext();

function App() {
  // Working method
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const selectedLang = urlParams.get("lang");

  // const selectedLang = window.location.href.split("#").pop();
  console.log("selectedLang", selectedLang);

  return (
    <div>
      <booleanContext.Provider value={selectedLang}>
        <Router>
          <Header />
          <Switch>
            <Route path={`/`} exact component={IntakeForm} />
          </Switch>
          <Footer />
        </Router>
      </booleanContext.Provider>
    </div>
  );
}

export default App;
