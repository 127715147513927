import React, { useState, useEffect, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import SrrHeader from "../shared/components/SrrHeader";
import Acknowledgement from "./Acknowledgement";
import RequestForm from "../shared/components/RequestForm";
import { getService } from "../shared/components/restApi";
import { languages } from "../shared/components/languages";
import { booleanContext } from "../App";

function IntakeForm() {
  const selectedLang = useContext(booleanContext);
  const getText = (attr) => {
    if (selectedLang) {
      return languages[selectedLang].intakeForm[attr];
    }
    return languages.en.intakeForm[attr];
  };
  const [loader, setLoader] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [configValues, setConfigValues] = useState({});
  const [relationshipType, setRelationshipType] = useState("");

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setRequestId(value);
  };

  const handleRelationshipType = (value) => {
    setRelationshipType(value);
  };

  // useEffect to get the configuration values
  useEffect(() => {
    let privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_CUSTOMER}`;
    if (relationshipType) {
      switch (relationshipType) {
        case getText("customer"):
          privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_CUSTOMER}`;
          break;
        case getText("vendor"):
          privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_VENDOR}`;
          break;
        case getText("employee"):
          privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_EMPLOYEE}`;
          break;
        default:
          break;
      }
    }
    let configOptions = {
      method: "GET",
      url: privacyFormURL,
    };
    const configData = getService(configOptions);

    configData
      .then((response) => {
        if (response.status === 200 && response.data) {
          const { requests, settingsId } = response.data.srrConfig;
          const actions = requests.reduce((actionsMap, request) => {
            actionsMap[request.requestType] = request;
            return actionsMap;
          }, {});

          let finalOrderedActions;

          // changing the order of actions object
          const objectTemp1 = {
            optOut: null,
            correction: null,
            deletion: null,
            portability: null,
            access: null,
            "optOut-data-processing": null,
          };

          const objectTemp2 = {
            correction: null,
            deletion: null,
            portability: null,
            access: null,
            "optOut-data-processing": null,
          };

          const finalObjTemp =
            relationshipType === getText("employee")
              ? objectTemp2
              : objectTemp1;

          if (Object.keys(actions).length > 0) {
            finalOrderedActions = Object.assign(finalObjTemp, actions);
          }

          const requestTypes = Object.keys(finalOrderedActions).map(
            (request) => {
              switch (request) {
                case "access":
                  return {
                    value: "access",
                    description: getText("accessDescription"),
                  };
                case "deletion":
                  return {
                    value: "deletion",
                    description: getText("deleteDescription"),
                  };
                case "correction":
                  return {
                    value: "correction",
                    description: getText("correctionDescription"),
                  };
                case "portability":
                  return {
                    value: "portability",
                    description: getText("portabilityDescription"),
                  };
                case "optOut":
                  return {
                    value: "optOut",
                    description: getText("optOutDescription"),
                  };
                case "optOut-data-processing":
                  return {
                    value: "optOut-data-processing",
                    description: getText("dataProcessingDescription"),
                  };
                default:
                  return console.warn("Unknown Request Type", request);
              }
            }
          );

          setConfigValues({
            actions,
            requestTypes,
            settings: settingsId,
            _id: settingsId,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipType, selectedLang]);

  return (
    <div>
      <SrrHeader heading={getText("title")} />

      <Container className="my-3">
        {loader ? (
          <div className="">
            <Spinner animation="grow" variant="primary" className="spinner" />
          </div>
        ) : showAcknowledgement ? (
          <Acknowledgement id={requestId} />
        ) : (
          <>
            <RequestForm
              configValues={configValues}
              enableLoader={handleLoader}
              getAcknowledgement={handleAcknowledgement}
              getSubmitRequestId={handleSubmitRequestId}
              getRelationshipType={handleRelationshipType}
              relationshipType={relationshipType}
            />
          </>
        )}
      </Container>
    </div>
  );
}

export default IntakeForm;
